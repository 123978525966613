@import './inicialized/variables.scss';
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url('https://fonts.cdnfonts.com/css/playlist');
@import url('https://fonts.cdnfonts.com/css/madina');
@import url('https://fonts.cdnfonts.com/css/lavanderia');

body {
  margin: 0;
  font-family: "Montserrat", Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div{
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
