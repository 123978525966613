@import '../inicialized/variables.scss';

.containerEventos{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.containerEventos>.header{
    background-color: $primary;
    width: 100%;
    height: 8vh;
    display: flex;
    position: relative;

}

.containerEventos>.header>.agregarEvento{
    position: absolute;
    color: #d6ccc2;
     font-size: 3rem; 
    top: 0vw;
    right: 0vw;
    cursor: pointer;
    width: 15vw;
    height: 15vw;
    display: flex;
    align-items: center;
    justify-content: center;
}




.containerEventos>.header>.logoAltezza{
    width: 18%;
    margin: 0 auto;
}

.containerEventos>.content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
    padding-inline: 1em;
    padding-top: 1em;
}

.containerEventos>.content>.evento{
    background-color: $SecondaryDark;
    //border: solid 1px $primary;
    display: flex;
    flex-direction: column;
    border-radius: 1.5vw;
    height: 35vw;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 12px -4px rgba(0,0,0,0.4); 
    box-shadow: 0px 0px 12px -4px rgba(0,0,0,0.4);
}