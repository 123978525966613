@import './variables.scss';

.generalInput {
  margin-top: 5vw !important;
}

.containerDirecColumn{
    flex-direction: column;
}

.input1Col80{
    width: 80%
}

.input1Col93{
    width: 93%
}

.input2Col{
    width: 45%;
    margin-inline: 5
}

.container1col {
    display: flex;
    width: 100%;
    height: 100%;
    align-items:center;
    justify-content: center;
    flex: 1;

}

.container2col {
    margin-top: 20;
    margin-bottom: 50;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    
}

.iconSmall{
    border-radius: 0;
    position: absolute;
    top: -30;
    right: -30;
    color: red;
    transform: {scale: 0.6}
}

.buttonUno{
    width: 70%;
    padding: 2vw;
    margin-top: 8vw !important;
    text-align: center;
    justify-content: center;
    border: solid 1px $Secondary;
    border-radius: 2vw;
}