@import '../../../inicialized/variables.scss';

.contentList{
    display: flex;
    gap: 16px;
}

.contentList .invitacionImport{
    border: solid 1px $Secondary;
    border-radius: 10px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
    font-size: 0.9em;
}

.contentList .invitacionImport .idInvImport{
    border-bottom: solid 1px $vistos;
    margin-bottom: 8px;
}

.contentList .invitacionImport .invitadoImport{
    display: flex;
    justify-content: space-between;
}

.importarInvitadosExcel .buttonsImport{
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 8px;
    margin-bottom: 16px;
}

.importarInvitadosExcel .buttonsImport>*{
    flex: 1;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.importarInvitadosExcel .buttonsImport .cancelar{
    border: 1px solid $promos;
}

.importarInvitadosExcel .buttonsImport .importar{
    border: 1px solid $infos;
}

.invitacionImport .mensajeInv{
    color: $gray2;
    border-top: solid 1px $gray1;
    padding-top: 8px;
}