$primary: #000000;
$primaryDark:#000000;
$primaryTransparent: #000000d0;

$Secondary: #d6ccc2;
$SecondaryDark: #EFD3C5;
$SecondaryLigth: #f8edeb;

$infos: #A8CF45;
$promos: #F33446;
$vistos: #0098DA;

$gray0: #ebebeb;
$gray1: #cecece;
$gray2: #797979;
$gray3: #444444;
$transparent: #ffffff00;

$white1: #fcf5ed;

$alert-color: #cb492a;
$light-black-color: #706e72;
$black-color: #414042;


$blurGeneral: 2px;



