@import '../../inicialized/variables.scss';

.slide{
    width: 100vw;
    height: 90vw;
    background: $gray1 url("./src/images/resized_1920x1080/002.jpg");
    background-position: center;
    background-size: cover;
    position: absolute;
    overflow: hidden;
    -webkit-box-shadow: 3px 5px 22px -3px rgba(0,0,0,0.89); 
    box-shadow: 3px 5px 22px -3px rgba(0,0,0,0.89);
    cursor: pointer;
}


.contenido{
    height: 100%;
    width: 100%;
    position: absolute;
}

.degradado{
  background-image: linear-gradient(0deg,rgba(0,0,0,1),rgba(0,0,0,0.0),rgba(0,0,0,0.0));
  width: 100%;
  height: 100%;
  bottom: 0em;
  position: absolute !important;
  z-index: 5;  
}

.iconoEmpresa{
  width: 8em;
  height: 8em;
  border-radius: 50%;
  position: absolute !important;
  bottom: 2em;
  right: 6.5em;
  z-index: 6;
  -webkit-box-shadow: 3px 5px 22px -3px rgba(0,0,0,0.89); 
  box-shadow: 3px 5px 22px -3px rgba(0,0,0,0.89);
}


.textoSlide {
  position: absolute !important;
  bottom: 10em;
  left: 5em;
  width: 50%;
  z-index: 10;
}

.textoSlide h1{
  font-size: 1.5em;
}


.textoSlide p{
  font-size: 0.8em;
}

.bg{
  width: 100vw;
  height: 90vw;
}
