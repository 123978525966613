@import '../../inicialized/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Dancing+Script&family=Marck+Script&display=swap');
@import "~react-image-gallery/styles/scss/image-gallery.scss";

#contentInvitacion {
    background-color: #fff;
}



.mute {
    position: absolute;
    top: 32px;
    right: 42px;
    width: 32px;
    height: 32px;
    display: flex;
    cursor: pointer;
}

.mute svg {
    width: 100%;
    height: 100%;
}

.mute path {
    fill: #ae482d !important;
    stroke: #ae482d !important;
    stroke-width: 1px !important;
}

.contentContainerWYM {
    width: 100%;
    height: fit-content;
    font-family: 'Bad Script', cursive !important;
    font-size: 1.3rem;
    background-image: url('./src/images/fondos/003.jpg');
    position: relative;
}

.contentContainerWYM h4 {
    font-size: 1.7em !important;
    color: #ae482d;
}

.contentContainerWYM h2 {
    font-size: 2.5em;
    color: #0e347d;
}

.contentContainerWYM h4,
.contentContainerWYM h2 {
    font-family: 'playlist', sans-serif;
}

.contentContainerWYM .envelop {
    position: fixed;
    width: 100svw;
    height: 100svh;
    z-index: 100000;
    top: 0;
    left: 0;
}

body:has(.envelop){
    overflow: hidden;
}

.contentContainerWYM .envelop .base {
    position: absolute;
    width: 100svw;
    height: 70svh;
    z-index: 100001;
    bottom: 0;
    background-image: url('./src/images/fondos/003-1.jpg');
    opacity: 1;
}

.contentContainerWYM .envelop .tapa {
    height: 150svw;
    width: 150svw;
    opacity: 1;
    transform: rotate(45deg);
    position: fixed;
    right: -24%;
    top: -30%;
    background-image: url('./src/images/fondos/003-1.jpg');
    border-radius: 35px;
    -webkit-box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.4);
    z-index: 100002;

}

.contentContainerWYM .envelop .tapa .sello {
    position: absolute;
    bottom: -19%;
    right: -16%;
    width: 150px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100003;
    opacity: 1;
    transform: rotate(-45deg) scale(1);
}

.contentContainerWYM .envelop * {
    transition: all 2s;

}

.contentContainerWYM .envelopOpen .tapa {
    top: -163svw;
    opacity: 0;
}

.contentContainerWYM .envelopOpen .sello {
    opacity: 0;
    transform: rotate(-45deg) scale(1.5);
}

.contentContainerWYM .envelopOpen .base {
    bottom: -80svh;
    opacity: 0;
}

.contentContainerWYM .envelop .sello span {
    width: fit-content;
    height: auto;
    padding: 2px 16px;
    border-radius: 10px;
    background-color: #ae482d;
    margin-top: 1em;
    -webkit-box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.4);
}

.contentContainerWYM .envelop .sello img {
    width: 120px;
    height: auto;
}

.contentContainerWYM .header {
    background-color: transparent;
    background-image: url('./src/images/fondos/007.png');
    background-size: 50%;
    background-position: -20px -20px;
    background-repeat: no-repeat;
    width: 100svw;
    height: 65vw;
    margin: 0;
    position: relative;
}

.contentContainerWYM .header .react-audio-player {
    position: absolute;
    top: 56px;
    right: -10px;
    transform: scale(0.7);
    background-color: transparent;
    color: #ae482d;
}

.padres {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 95px;
}

.padres .infocompleta {
    margin-top: 100px;
    padding: 40px 24px 80px 24px;
    position: relative;
    overflow: visible;
    border: #CE9B4E 2px solid;
}

.padres .infocompleta::after {
    position: absolute;
    content: '';
    bottom: -100px;
    right: -60px;
    width: 250px;
    height: 250px;
    background-image: url('./src/images/fondos/flor-005.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.padres .infocompleta::before {
    position: absolute;
    content: '';
    top: -100px;
    left: -60px;
    width: 250px;
    height: 250px;
    background-image: url('./src/images/fondos/flor-005.png');
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotate(180deg);
}


.fallecido {
    padding-right: 18px;
}

.fallecido::after {
    content: '';
    width: 24px;
    height: 24px;
    top: 10px;
    left: -10px;
    position: absolute;
    background-image: url('https://images.vexels.com/media/users/3/154656/isolated/preview/f15e02904d61cd38ed16ad47691e5c91-icono-de-cruz-cristiana-larga.png');
    background-size: 18px;
    background-repeat: no-repeat;
}


.padres span {
    text-align: center;
    padding: 0 8px;
    border-top: 1px dotted #ae482d;
    border-bottom: 1px dotted #ae482d;
    position: relative;
    z-index: 1000;
}

.padres h2,
.padres h4 {
    margin: 16px auto;
    text-align: center;
}



.contentContainerWYM>.slideFotos {
    width: 100%;
    height: 90vw;
    margin: 16px 0 16px 0;
}





.sectionInv {
    width: 82%;
    height: fit-content;
    margin: 4vw 9vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*  border: solid 1px $gray1; */
    text-align: center;
}

.sectionLogo {
    width: 100svw;
    display: flex;
    margin-top: -20svw;
}

.sectionLogo img {
    width: 90%;
    margin: 0px auto;
}

.sectionNom {
    margin: 0 auto !important;
}

.bienvenida {
    margin: 0 !important;
    width: 100svw;
}


.rose-logo path {
    fill: #ae482d;
}

.fraseIntro {
    width: 100svw;
    height: 100svw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.fraseIntro video {
    position: absolute;
    bottom: -35svw;
}

.fraseIntro .info {
    z-index: 10000;
    position: absolute;
    bottom: 24px;
    display: flex;
    flex-direction: column;
    color: #fff;
    transform: scale(1.2);
    filter: drop-shadow(2px 4px 6px black);

}


.frase {
    text-align: center;
}

.separador-flor {
    height: 15svh;
    width: 100svw;
    background-image: url('./src/images/fondos/004.png');
    background-size: 130svw;
    background-repeat: no-repeat;
    background-position: center;
}

.flor-abajo {
    transform: rotate(-180deg);
}


.historia {
    position: relative;
    margin: -2px 0 0 0;
    width: 100svw;
    min-height: 200svw;
    padding: 48px 0px 80px 0px;
}

.historia span {
    margin: 16px 32px;
    z-index: 100;
}

.historia::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100svw;
    height: 50svw;
    background-image: url('./src/images/fondos/008.png');
    background-repeat: no-repeat;
    background-size: cover;
}


.historia::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100svw;
    height: 50svw;
    background-image: url('./src/images/fondos/008.png');
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotate(180deg);
}

.historiaP .content h4 {
    position: relative;
    width: fit-content;
    margin: 1.33em auto;
}

.historiaP .contentt h4::after {
    content: '';
    background-image: url('./src/images/fondos/flor-005.png');
    position: absolute;
    top: -10px;
    right: -11svw;
    width: 15svw;
    height: 15svw;
    transform: rotate(240deg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.historiaP>.header>span {
    position: absolute;
    top: 14vw;
    font-size: 2.5rem;
    width: 42%;
    text-align: center;
    left: 24vw;
}

.datosEvento {
    margin: 16px 0;
    display: flex;
    width: 100%;
    justify-content: center;
}

.datosEvento .ceremonia-titulo {
    background-image: url('https://www.feegosystem.com/srcAltezza/images/fondoCeremonia.jpg');
}

.datosEvento .recepcion-titulo {
    background-image: url('https://www.feegosystem.com/srcAltezza/images/fondoCelebracion001.jpg');
    backdrop-filter: blur;
}

.datosEvento .parallax {
    min-height: 200px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-attachment: fixed;
    background-position: center;
    background-repeat: repeat-y;
    background-size: contain;
}

.datosEvento .tarjeta {
    padding-bottom: 24px;
}

.datosEvento .tarjeta .info {
    padding: 16px;
}

.datosEvento>.tarjeta>img {
    width: 100%;
}


.datosEvento>.tarjeta>.info {
    flex-direction: column;
    display: flex;
    margin-top: 5vw;
}

.datosEvento h2 {
    font-size: 1.8em;
    margin: 24px auto;
}

.datosEvento>h4 {
    color: #ae482d;

}

.datosEvento h4 {
    font-size: 1.3em;
    margin: 5px auto;
}

.datosEvento>.tarjeta>.info>.ubicacion {
    background-color: #0e347d;
    width: 50%;
    margin: 4vw auto;
    border-radius: 1.5vw;
}

.datosEvento>.tarjeta>.info>.ubicacion>a {
    text-decoration: none;
    color: #fff;
}

.dressCode>.colores>.colorRes {
    width: 9vw;
    height: 9vw;
    border-radius: 50%;
    border: solid 1px $gray1;
}

.dressCode>.colores {
    display: flex;
    gap: 16px;
    justify-content: center;
}

.datosEvento>.hashtag {

    margin-top: 5vw;
}

.datosEvento .iconDatos {
    width: 40%;
    height: auto;
    margin: auto;
}

.datosEvento .copas {
    width: 35%;
}

.datosEvento .iconDatos img {
    width: 100%;
    height: auto;
}

.historiaP {
    width: 100svw;
    margin: 0;
}

.historiaP>.content {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
}

.historiaP>.content h4 {
    text-align: center;
    font-size: 1.3em;
}

.historiaP>.content span {
    margin: 0 32px;
}

.historiaP>.content>.separador {
    height: 8vw;
    width: 50%;
    border-right: solid 1px $gray1;
    margin-top: 1.3rem;

}

.fotos {
    width: 100svw;
    min-height: 200svw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: -100px;
}


.fotos .foto {
    width: 50svw;
    height: fit-content;
    background-color: #fff;
    padding: 16px 16px 24px 16px;
}

.fotos .foto img {
    width: 100%;
}

.fotos .foto1 {
    position: relative;
    transform: rotate(10deg);
    left: -10px;
    top: 48px;
}

.fotos .foto2 {
    position: relative;
    transform: rotate(-10deg);
    right: 44px;
    width: 45svw;
}

.fotos .foto3 {
    position: relative;
    transform: rotate(-10deg);
    left: -10px;
    top: -10px;
}

.fotos .foto4 {
    position: relative;
    transform: rotate(10deg);
    right: 20px;
    top: -10px;
}

.fotos .foto5 {
    position: relative;
    transform: rotate(10deg);
    left: -30px;
    top: -70px;
}

.fotos .foto6 {
    position: relative;
    transform: rotate(-10deg);
    right: 40px;
    top: -40px;
}

.fotos .foto7 {
    position: relative;
    transform: rotate(-10deg);
    left: -10px;
    top: -140px;
}

.fotos .foto8 {
    position: relative;
    transform: rotate(10deg);
    right: 50px;
    top: -100px;
}


.rose-logo {
    text-align: center;
    margin: auto;
}

.countDown {
    background-size: cover;
    background-color: transparent;
    filter: drop-shadow(0px 0px 2px #0e347d);
    border: #0e347d solid 1px;
    color: #0e347d;
    border-radius: 5vw;
    left: -4vw;
    position: relative;
    font-size: 0.9em;
    padding-bottom: 32px;
}



.countDown>.content {
    display: flex;
    flex-direction: column;
}

.galeria {
    display: initial !important;
    width: 100vw !important;
    margin: 0vw !important;
}

.image-gallery>* {
    display: initial !important;
    margin-top: 5vw;
}

.teEsperamos {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0;
    width: 100%;
    height: 100svw;
}

.teEsperamos>.imgTeEss {
    width: 80svw;
    height: 80svw;
    background-image: url('http://www.feegosystem.com/srcAltezza/images/invitaciones/WYM/023.webp');
    background-color: #0e347d;
    background-size: cover;
    background-position-y: -21svw;
    background-repeat: no-repeat;

    mask-image: url('./src/icons/corazon.svg');
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: 1svw;

}

.teEsperamos .corazon {
    background-image: url('./src/images/fondos/002.png');
    background-size: contain;
    width: 100%;
    z-index: 1000;
    height: 100svw;
    position: absolute;
}


.teEsperamos>.imgTeEs>img {
    height: 100%;
    transform: scale(1);
    right: 0vw;
    top: 0.1vw;
    position: absolute;

}

.teEsperamos>span {
    font-size: 2rem;
    line-height: 9vw;
    text-align: center;
}

.vestuario {
    margin: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.vestuario .icons {
    display: flex;
    width: 80%;
    gap: 24px;
}

.vestuario .icons .damas,
.vestuario .icons .caballeros {
    flex: 1;
    display: flex;
    flex-direction: column;

}

.vestuario .icons .damas span,
.vestuario .icons .caballeros span {
    font-size: 0.8em;
}


.vestuario .icons .damas img,
.vestuario .icons .caballeros img {
    width: 100%;
}

.datosEvento .separador-flor {
    margin-left: 0 !important;
}

.lluviaSobres>img {
    width: 15vw;
}

.lluviaSobres {
    background-image: url('./src/images/fondos/006.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    margin: 0;
    min-height: 500px;


}

.lluviaSobres span {
    font-size: 1.1em;
    line-height: 1.3;
    width: 50%;
    padding-top: 24px;
}

.confirmacion {
    width: 100%;
    height: fit-content;
    margin-left: 0;
}



.formAsis {
    border: solid 1px $SecondaryDark;
    width: 95%;
    margin-bottom: 24px;
    border-radius: 3vw;
    background-image: url('./src/images/fondos/003-1.jpg');
}

.formAsis span {
    color: #fff !important;
}

.formAsis label {
    color: #0e347d !important;

}

.confirmacionn {
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center top;
    background-attachment: scroll;
    background-size: auto;
    border-top-width: 1px;
    border-right-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    padding: 0;
    display: flex;
    width: 100%;
    margin: 0;
}

.confirmacionn .countDown {
    margin: 16px auto !important;
    width: 85%;
    left: 0;
    padding-top: 26px;
}

.confirmacionn .countDown::before {
    content: 'Confirmanos tu asistencia antes del 10 de Junio';

}

.confirmacionn .countDown h3 {
    display: none;
}

.nombreInvitado {
    color: #C3A17D !important;
    font-size: 1.8rem !important;
    font-family: 'Bad Script', cursive !important;
}

.FormControlLabel span {
    font-family: 'Bad Script', cursive !important;
    font-size: 1rem !important;
}

.radioGroupAsis {
    justify-content: center;
}

.confAsis {
    margin-top: 8vw !important;
}

.confirmar {
    background-color: #0e347d;
    color: #fff;
    width: 50%;
    margin: 4vw auto;
    border-radius: 1.5vw;
}

.cargando {
    z-index: 120;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.cargando img {
    width: 20%;
}

.cargando span {
    text-align: center;
}

.just {
    padding: 16px;
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
}

.just h4,
.just span{
    font-size: 1em !important;
}

.nota {
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    font-size: 0.9em;
    text-align: center;
}

.just span strong {
    font-size: 0.8em;
}

.footer {
    transform: rotate(180deg);

}